import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import LucarioWhiteSvg from '../assets/images/lucario_white.svg';
import styles from '../styles/main.module.scss';

const IndexPage = () => (
  <Layout>
    <SEO title="Welcome" />
    <div className={styles.container}>
      <div>
        <LucarioWhiteSvg className={styles.content} />
      </div>
    </div>
  </Layout>
);

export default IndexPage;
